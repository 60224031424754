html {
  font-size: 20px;
}

body {
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: flex;
}

#dashboard-layout {
  display: flex;
  width: 100%;
  height: 100vh;
}

#dashboard-contents {
  background-color: #F6F6F6;
  flex-grow: 1;
  padding: 110px 90px;
  overflow: auto;
}

#sidebar {
  width: 300px;
  background-color: #323439;
  padding: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

#sidebar-logo {
  height: 50px;
  display: block;
}

#sidebar-logo-wrapper {
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #5D5D5D;
  margin-bottom: 20px;
}

.sidebar-item {
  cursor: pointer;
  display: flex;
  padding: 14px;
  color: #C9C9C9;
  align-items: center;
  font: inherit;
  font-size: 0.9rem;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  text-align: left;
  .material-symbols-outlined {
    margin-right: 12px;
  }
  &:hover {
    color: #ffffff;
  }
  &.active {
    color: #F47401;
  }
}

#sidebar-email {
  font-size: 0.7rem;
}

#sidebar-nav {
  flex-grow: 1;
}

#sidebar-bottom {
  .sidebar-item {
    align-items: flex-start;
  }
}

.btn {
  border-radius: 4px;
  border: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 16px 40px;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  .material-symbols-outlined {
    margin-right: 10px;
  }
  &.short {
    padding: 6px 40px;
  }
  &.primary {
    background-color: #F47401;
    color: #ffffff;
  }
}



/**********************************************************
                        Login screen
**********************************************************/

#login_wrapper {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F9FAFF;
}

#login_div {
  background-color: #FFFFFF;
  box-shadow: 0 0 30px #0000001F;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  width: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#login_logo {
  height: 40px;
  margin: 0 auto;
  display: block;
}

#login_contents {
  padding: 22px;
  display: flex;
  flex-direction: column;
  h4 {
    text-align: center;
  }
}

#login_logo_wrapper {
  padding: 18px 0;
  border-bottom: 1px solid #F0F0F0;
  width: 100%;
}

#login_terms {
  font-size: 0.7rem;
  line-height: 1.7;
  a {
    color: inherit;
  }
}

#login_captcha {
  font-size: 0.7rem;
  line-height: 1.7;
  color: #A7A7A7;
}

#login_btn {
  background-color: #ffffff;
  border: 1px solid #E2E2E2;
  border-radius: 8px;
  font: inherit;
  font-size: 0.8rem;
  font-weight: bold;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  &:hover {
    background-color: #E2E2E2;
  }
  span {
    flex-grow: 1;
    text-align: center;
  }
}

#login_btn_icon {
  width: 30px;
  height: 30px;
}


/**********************************************************
                      Landing screen
**********************************************************/
#landing-logo {
  height: 50px;
}

.landing-width {
  width: 100%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
}

#landing-header {
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#landing-flex {
  display: flex;
  padding-top: 100px;
}

#landing-illustration {
  width: 100%;
  max-width: 800px;
}

#landing-content {
  margin-top: 50px;
  h1 {
    font-size: 49px;
    line-height: 68px;
  }
  p {
    font-size: 18px;
    line-height: 2;
  }
}

#landing-google-icon {
  margin-right: 20px;
  width: 22px;
  height: 22px;
}

@media screen and (max-width: 1200px) {
  #landing-flex {
    flex-direction: column;
    padding-top: 0;
  }
  #landing-content {
    margin: 20px 0 100px;
  }
  #landing-illustration {
    align-self: flex-end;
  }
}

/**********************************************************
                    Meeting list screen
**********************************************************/
#meeting-list {
  display: flex;
  flex-wrap: wrap;
}

.meeting-list-item {
  background-color: #ffffff;
  padding: 20px 40px 20px 20px;
  border-radius: 8px;
  margin: 0 20px 20px 0;
  box-shadow: 0 0 30px #0000001F;
  text-decoration: none;
  color: inherit;
  display: inline-flex;
  .material-symbols-outlined {
    margin-right: 10px;
    font-size: 40px;
    color: #5D5D5D;
  }
}

.meeting-item-title {
  font-weight: bold;
  margin: 0;
}

.meeting-item-contents {
  padding: 5px 0;
}

.meeting-item-invited {
  font-size: 16px;
}

#chrome-webstore-banner {
  display: flex;
  box-shadow: 0 0 30px #0000001F;
  padding: 0 30px 40px;
  background-color: #FFFFFF;
  border-radius: 4px;
  align-items: flex-start;
  margin-bottom: 60px;
}

#chrome-banner-icon {
  width: 148px;
}

#chrome-banner-contents {
  margin-left: 36px;
  flex-grow: 1;
  h4 {
    margin: 40px 0 20px;
  }
  p {
    margin: 0;
  }
}

#chrome-banner-btn {
  margin: 50px 50px 0 40px;
}

/**********************************************************
                  Meeting details screen
**********************************************************/
.meeting-det-time-item {
  display: flex;
  line-height: 2;
  margin-bottom: 20px;
  .radio-button-icon {
    margin: 8px 12px 0 0;
  }
}
.meeting-det-confirmed {
  display: flex;
  align-items: center;
  font-size: 18px;
}
.meeting-det-confirmed-item {
  display: inline-flex;
  align-items: center;
  //margin-right: 20px;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.meeting-det-cal {
  display: flex;
  box-shadow: 0 0 30px #0000001F;
  padding: 24px;
  background-color: #FFFFFF;
  border-radius: 4px;
  align-items: flex-start;
  margin: 30px 0 20px;
}

.meeting-det-cal-icon {
  width: 48px;
}

.meeting-det-cal-contents {
  margin-left: 20px;
  flex-grow: 1;
  h4 {
    margin: 0 0 10px;
  }
  p {
    margin: 0 0 10px 0;
    line-height: 1.8;
  }
}

.attendees-list {
  line-height: 1.7;
  font-size: 18px;
}

.det-inexistent {
  line-height: 1.7;
  font-size: 18px;
}

.progress-blink {
  background-color: #FFD443;
  background-size: 40%;
  box-shadow:
          0 1px 2px #FFD44366,
          0 1px 2px #FFD44366;
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 32px 20px 0 0;

  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: shadow;
}

@keyframes shadow {
  from {
    box-shadow: 0 0 0 5px #FFD44366;
  }

  50% {
    box-shadow:
            0 0 0 12px #FFD44366,
            0 0 0 4px #FFD44366;
  }
  to {
    box-shadow: 0 0 0 5px #FFD44366;
  }
}

.meeting-det-timings {
  flex-grow: 1;
}

.meeting-det-timings-text {
  line-height: 1.8;
  font-size: 16px;
}

.meeting-det-invitees {
  padding-right: 100px;
}

.meeting-det-invitees-item {
  margin: 0 8px;
  display: flex;
}

#chrome-banner-btn {
  margin: 50px 50px 0 40px;
}

.meeting-det-invitee-icon {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 50%;
  margin-right: 10px;
  &.confirmed {
    color: #00B715;
    border: 2px solid #00B715;
  }

  &.unconfirmed {
    color: #E47B2F;
    border: 2px solid #E47B2F;
  }

  &.inexistent {
    color: #AAAAAA;
    border: 2px solid #AAAAAA;
  }
}

.det-invitees-item-email {
  font-size: 14px;
}

#md-timeline {
  display: flex;
  position: relative;
}

.md-timeline-item {
  display: flex;
  flex-direction: column;
  width: 25%;
  align-items: center;
  text-align: center;
  font-size: 18px;
  color: #B2B0B0;
  p {
    margin-top: 4px;
  }
  .md-timeline-icon-highlight {
    display: none;
  }
  .md-timeline-dot {
    border: 6px solid #CECECE;
  }
  &.present {
    color: #222222;
    .md-timeline-icon {
      display: none;
    }
    .md-timeline-icon-highlight {
      display: initial;
    }
    .md-timeline-dot {
      border: 6px solid #FFD443;
    }
  }
  &.past {
    .md-timeline-dot {
      border: 6px solid #13C356;
    }
  }
}

.md-timeline-icon, .md-timeline-icon-highlight {
  width: 50px;
}

.md-timeline-dot {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-bottom: 12px;
  z-index: 10;
  background-color: #ffffff;
  position: relative;
}

.md-timeline-line {
  width: 75%;
  position: absolute;
  height: 8px;
  left: 12.5%;
  top: 14px;
  z-index: 1;
  background-color: #CECECE;
  &.highlight {
    background-color: #13C356;
  }
  &.AWAITING_ACCESS {
    width: 25%;
  }
  &.TIMINGS {
    width: 50%;
  }
  &.ON_CALENDAR {
    width: 75%;
  }
}

/**********************************************************
                       Common styles
**********************************************************/

.page-back {
  display: inline-flex;
  text-decoration: none;
  color: #5D5D5D;
  font-size: 16px;
  &:hover {
    color: #F47401;
  }
  .material-symbols-outlined {
    font-size: 20px;
  }
}

.radio-button-icon {
  width: 24px;
  height: 24px;
  border: 2px solid #0256b7;
  border-radius: 4px;
  margin-right: 5px;
  margin-top: 1px;
  cursor: pointer;
  display: inline-flex;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  .material-symbols-outlined {
    display: none;
  }
  &.selected {
    background-color: #0256b7;
    .material-symbols-outlined {
      display: initial;
    }
  }
}

#static-page-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  line-height: 1.8;
  h1 {
    font-size: 1.6rem;
  }
  h2 {
    font-size: 1.1rem;
  }
  p, ul {
    font-size: 0.8rem;
  }
}
#auth-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  font-size: 20px;
  line-height: 28px;
  .circle-loader {
    margin-right: 10px;
  }
}

#auth-loading-logo {
  height: 40px;
  display: block;
  margin-bottom: 30px;
}

.circle-loader {
  display: inline-block;
  border: 4px solid rgba(244, 116, 1, 0.4);
  border-top: 4px solid #F47401;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1.6s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

#ext-success-wrapper {
  width: 100%;
  max-width: 900px;
  padding: 0 20px;
  margin: 0 auto;
  h1 {
    text-align: center;
  }
}

#ext-success-flex {
  display: flex;
}

#ext-success-img {
  width: 440px;
}

#ext-success-content {
  flex-grow: 1;
  font-size: 0.8rem;
  padding-top: 20px;
}

#header-dash-link {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
}
